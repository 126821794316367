import { useNavigate } from "react-router";
import Main from "../layouts/Main";
import { motion } from "framer-motion";
import { BsInstagram, BsBehance } from "react-icons/bs";
import { FaLinkedinIn, FaArrowRight } from "react-icons/fa";
import useModal from "../hooks/useModal/useModal";
import LoginModal from "./LoginModal";
import "./HomePage.css";
export default function HomePage() {
  const navigate = useNavigate();
  const [showLoginModal, handleCloseLoginModal, handleShowLoginModal] =
    useModal();

  return (
    <Main>
      <div id="homepage">
        <section className="d-flex flex-column flex-sm-row hero mx-auto">
          <div className="introduction order-2 order-sm-1">
            <div className="hi-text fw-bold">
              Hi!
              <br /> I'm <span className="text-main">Mahsa</span> a
              <br /> UI/UX Designer
            </div>
            <div className="sub-text-role d-none d-sm-block">
              And a Graphic/Visual Designer
            </div>
            <div className="sub-text-location d-none d-sm-block">
              Based in Toronto, Canada.
            </div>
            <div className="sub-text mt-3 d-none d-sm-block">
              Who turns your paper ball into paper plane.
            </div>
            <div className="paper-plane">
              <motion.svg
                width="335"
                height="115"
                viewBox="0 0 462 158"
                xmlns="http://www.w3.org/2000/svg"
              >
                <motion.polygon
                  fill="#30719E"
                  className="morph"
                  // points="460.677372 157.296036 112.758683 38.9677241 119.111306 145.719727"
                  initial={{
                    points:
                      "460.677372 157.296036 112.758683 38.9677241 119.111306 145.719727",
                  }}
                  animate={{
                    points: [
                      "460.677372 125.296036 108.040017 24.8652344 112.454079 125.296036",
                      "460.677372 157.296036 112.758683 38.9677241 119.111306 145.719727",
                    ],
                  }}
                  transition={{
                    repeat: Infinity,
                    duration: 2,
                    repeatType: "reverse",
                  }}
                />
                <motion.polygon
                  fill="#5193C6"
                  className="morph2"
                  // points="112.758683 38.9677241 184.146186 0 460.677372 157.296036"
                  initial={{
                    points:
                      "112.758683 38.9677241 184.146186 0 460.677372 157.296036",
                  }}
                  animate={{
                    points: [
                      "108.040017 24.8652344 168.780251 0 460.677372 125.296036",
                      "112.758683 38.9677241 184.146186 0 460.677372 157.296036",
                    ],
                  }}
                  transition={{
                    repeat: Infinity,
                    duration: 2,
                    repeatType: "reverse",
                  }}
                />
                <motion.polygon
                  fill="#5193C6"
                  className="morph3"
                  // points="78.250435 57.8044647 460.677372 157.296036 0 100.518402"
                  initial={{
                    points:
                      "78.250435 57.8044647 460.677372 157.296036 0 100.518402",
                  }}
                  animate={{
                    points: [
                      "75.3147561 38.1354167 460.677372 125.296036 0 68.5184024",
                      "78.250435 57.8044647 460.677372 157.296036 0 100.518402",
                    ],
                  }}
                  transition={{
                    repeat: Infinity,
                    duration: 2,
                    repeatType: "reverse",
                  }}
                />
              </motion.svg>
            </div>
          </div>
          <div className="profile-section order-1 order-sm-2">
            <img
              src="/assets/img/Home/me-profile-pic.jpg"
              alt="portfolio face"
              className="avatar-wobble"
            />
          </div>
          <div className="social-container1 d-none d-sm-block order-sm-3">
            <a
              className="social-icon"
              href="https://www.linkedin.com/in/mahsa-hm-ebrahim/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn />
            </a>
            <a
              className="social-icon"
              href="https://www.behance.net/mahsaeb"
              target="_blank"
              rel="noreferrer"
            >
              <BsBehance />
            </a>
            <a
              className="social-icon"
              href="https://www.instagram.com/moonsa_art/?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              <BsInstagram />
            </a>
          </div>
        </section>
        <div className="py-3 py-lg-16 my-4"></div>
        <section id="section-youtube">
          <div className="title dm-sans">
            Join Me on My Tutorial Journey on YouTube!
          </div>
          <div className="card">
            <a href="https://www.youtube.com/@design_with_mahsa" target="_blank">
              <img
                src="/assets/img/Home/youtube-thumbnail.png"
                alt=""
                className="img-fluid"
              />
            </a>
          </div>
        </section>
        {/* <section className="row align-items-center justify-content-center">
          <div
            className="row justify-content-center justify-content-sm-end px-0"
            style={{
              width: "75%",
              maxWidth: "1080px",
            }}
          >
            <a
              href="https://cdn.knightlab.com/libs/juxtapose/latest/embed/index.html?uid=08e3ae56-36d4-11ee-b5bd-6595d9b17862"
              target="_blank"
              rel="noreferrer"
              className="col-12 col-sm-6 px-0"
            >
              <div
                className="crafting-video-container d-flex justify-content-center me-sm-4"
                style={{
                  backgroundColor: "#5193C6",
                  borderRadius: "25px",
                }}
              >
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  preload="auto"
                  alt=""
                  style={{ padding: "3%", borderRadius: "25px" }}
                  className="img-fluid crafting-video"
                >
                  <source
                    src={"/assets/video/juxtapose.mp4"}
                    type="video/mp4"
                  />
                </video>
              </div>
            </a>
            <div className="col-12 col-sm-6 d-flex flex-column justify-content-center px-0 mt-4 mt-sm-0 ps-sm-10">
              <div
                className="crafting-title fw-bold mb-sm-4"
                style={{ color: "#5193C6", lineHeight: 1.2 }}
              >
                Crafting Seamless Interfaces
              </div>
              <div className="crafting-subtitle">
                Transforming Ideas into Seamless Interfaces. Specializing in
                Product Design, Branding, and Web Solutions to Elevate User
                Experiences
              </div>
            </div>
          </div>
        </section> */}
        <div className="py-3 py-sm-8"></div>
        <div className="w-100" id="sec-cards-wrapper">
          <div className="fw-bold text-center fs40 text-main py-4">
            Selected Works
          </div>
          <section id="sec-cards">
            <div
              className="card"
              onClick={() => navigate("/onra")}
              role="button"
            >
              <div className="img-container">
                <img
                  src="/assets/img/Home/onra.png"
                  className="card-img-top"
                  alt="Onra.ca"
                />
              </div>
              <div className="card-body">
                <div>
                  <h5 className="card-title">Onra.ca</h5>
                  <p className="card-text">
                    Real State Agency <br />
                    https://www.onra.ca/{" "}
                  </p>
                  <p className="card-subtext">
                    Bridging the gap btw home buyers & real estate projects{" "}
                  </p>
                </div>
                <a className="btn" onClick={() => navigate("/onra")}>
                  View{" "}
                  <FaArrowRight style={{ width: "15px" }} className="ms-1" />
                </a>
              </div>
            </div>
            <div
              className="card"
              onClick={() => navigate("/sib_bakery")}
              role="button"
            >
              <div className="img-container">
                <img
                  src="/assets/img/Home/Sib-Bakery.png"
                  className="card-img-top"
                  alt="sib bakery"
                />
              </div>
              <div className="card-body">
                <div>
                  <h5 className="card-title">Sib Bakery</h5>
                  <p className="card-text">Local Bakery Based in Seattle</p>
                  <p className="card-subtext">
                    Providing easy access to buy delicious bakery products
                  </p>
                </div>
                <a className="btn" onClick={() => navigate("/sib_bakery")}>
                  View{" "}
                  <FaArrowRight style={{ width: "15px" }} className="ms-1" />
                </a>
              </div>
            </div>
            <div
              className="card"
              onClick={() => navigate("/plando")}
              role="button"
            >
              <div className="img-container">
                <img
                  src="/assets/img/Home/Left_laptop.png"
                  className="card-img-top"
                  alt="sib bakery"
                />
              </div>
              <div className="card-body">
                <div>
                  <h5 className="card-title">Plando</h5>
                  <p className="card-text">
                    Planner and Goal-tracking Web Application
                  </p>
                  <p className="card-subtext">
                    Offering a customizable planner to monitor progress
                  </p>
                </div>
                <a className="btn" onClick={() => navigate("/plando")}>
                  View{" "}
                  <FaArrowRight style={{ width: "15px" }} className="ms-1" />
                </a>
              </div>
            </div>
            <div
              className="card"
              onClick={() => navigate("/ecycle")}
              role="button"
            >
              <div className="img-container">
                <img
                  src="/assets/img/Home/Phone_eCycle_Home.png"
                  className="card-img-top"
                  alt="sib bakery"
                />
              </div>
              <div className="card-body">
                <div>
                  <h5 className="card-title">eCycle </h5>
                  <p className="card-text">
                    e-Bike Sharing System Mobile Application
                  </p>
                  <p className="card-subtext">Tools: Figma, Ilustrator, Maze</p>
                </div>
                <a className="btn" onClick={() => navigate("/ecycle")}>
                  View{" "}
                  <FaArrowRight style={{ width: "15px" }} className="ms-1" />
                </a>
              </div>
            </div>
            <div
              className="card"
              onClick={() => navigate("/ftd")}
              role="button"
            >
              <div className="img-container">
                <img
                  src="/assets/img/Home/Laptop_FTD_Home.png"
                  className="card-img-top"
                  alt="sib bakery"
                />
              </div>
              <div className="card-body">
                <div>
                  <h5 className="card-title">FTD </h5>

                  <p className="card-text">Flower Online Shop Website </p>
                  <p className="card-subtext">
                    Redesign the homepage with a focus on adhering to WCAG
                    standards criteria
                  </p>
                </div>
                <a className="btn" onClick={() => navigate("/ftd")}>
                  View{" "}
                  <FaArrowRight style={{ width: "15px" }} className="ms-1" />
                </a>
              </div>
            </div>
            <div
              className="card"
              onClick={() => navigate("/apple-dry")}
              role="button"
            >
              <div className="img-container">
                <img
                  src="/assets/img/Home/apple-dry.png"
                  className="card-img-top"
                  alt="sib bakery"
                />
              </div>
              <div className="card-body">
                <div>
                  <h5 className="card-title">Apple Dry</h5>
                  <p className="card-text">
                    Online Shop for Organic Dried Fruits
                  </p>
                  <p className="card-subtext">
                    Providing easy access to buy healthy dried fruit
                  </p>
                </div>
                <a className="btn" onClick={() => navigate("/apple-dry")}>
                  View{" "}
                  <FaArrowRight style={{ width: "15px" }} className="ms-1" />
                </a>
              </div>
            </div>
            <div
              className="card"
              onClick={() => navigate("/other")}
              role="button"
            >
              <div className="img-container">
                <img
                  src="/assets/img/Home/Other_Home.png"
                  className="card-img-top"
                  alt="sib bakery"
                />
              </div>
              <div className="card-body">
                <div>
                  <h5 className="card-title">Graphic Design and ... </h5>
                  <p className="card-text">
                    Graphic Design, Illustrations, 3d Modelings, Sketching,
                    Motion Graphics, ...{" "}
                  </p>
                </div>

                <a className="btn" onClick={() => navigate("/other")}>
                  View{" "}
                  <FaArrowRight style={{ width: "15px" }} className="ms-1" />
                </a>
              </div>
            </div>
            <div
              className="card"
              onClick={() => handleShowLoginModal()}
              role="button"
            >
              <div className="img-container">
                <img
                  src="/assets/img/Home/hillzd.png"
                  className="card-img-top"
                  alt="sib bakery"
                />
              </div>
              <div className="card-body">
                <div>
                  <h5 className="card-title">Hillz Dealer </h5>
                  <p className="card-text">
                    Web-based Dealer Management System
                  </p>
                  <p className="card-subtext">
                    Designed specifically for the used car dealership
                  </p>
                </div>

                <a className="btn" onClick={() => handleShowLoginModal()}>
                  View{" "}
                  <FaArrowRight style={{ width: "15px" }} className="ms-1" />
                </a>
              </div>
            </div>
          </section>
          <div className="py-4"></div>
        </div>
        <section id="sec-skills">
          <div className="d-flex w-100 flex-column flex-sm-row justify-content-center justify-lg-content-between">
            <div className="social-container2 d-none d-xl-block">
              <a
                className="social-icon"
                href="https://www.linkedin.com/in/mahsa-hm-ebrahim/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedinIn />
              </a>
              <a
                className="social-icon"
                href="https://www.behance.net/mahsaeb"
                target="_blank"
                rel="noreferrer"
              >
                <BsBehance />
              </a>
              <a
                className="social-icon"
                href="https://www.instagram.com/moonsa_art/?hl=en"
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram />
              </a>
            </div>
            <img
              src="/assets/img/Home/Handshake.jpg"
              alt=""
              className="section-pic d-flex d-sm-none d-xl-flex px-10"
            />
            <div className="row list text-main">
              <div className="ms-3 mb-8 fw-bold fs40">Skills</div>
              <div className="col">
                <span className="fw-bold">UX</span>
                <ul>
                  <li>Agile Methodology</li>
                  <li>UX Strategy | Design Thinking</li>
                  <li>Design System</li>
                  <li>Information Architecture</li>
                  <li>Competitive Analysis</li>
                  <li>Usability Testing | A/B Testing</li>
                  <li>Human Interface Guidelines</li>
                  <li>Wireframing | Prototyping</li>
                  <li>Interaction Design</li>
                  <li>Responsive Design</li>
                </ul>
              </div>
              <div className="col">
                <span className="fw-bold d-block text-end text-sm-start">
                  Problem Solving
                </span>
                <ul className="problem-solving">
                  <li>Brand Identity</li>
                  <li>Marketing Strategy</li>
                  <li>User Psychology</li>
                  <li>Project Management</li>
                  <li>Storytelling</li>
                </ul>
                <span className="fw-bold">UI+ Visuals</span>
                <ul>
                  <li>Graphic Design</li>
                  <li>Desktop & phone Design</li>
                  <li>Sketch | Figma | Xd</li>
                  <li>Adobe Creative Suite</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="py-8"></div>
        </section>
        <LoginModal show={showLoginModal} onClose={handleCloseLoginModal} />
      </div>
    </Main>
  );
}
